import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { PublishModel } from '../models/nebulae/publishModel';
import { PublishModelError } from '../models/nebulae/publishModelError';
import { LocalStorageKeys } from '../../common/constant';
import { ToastrService } from 'ngx-toastr';
import { MqttService, IMqttMessage, IMqttServiceOptions } from 'ngx-mqtt';
import { LoginService } from './login.service';

declare var MqttPortalTopicPrefix: any;
declare var apiConfig: any;
export enum PayloadType {
  get_gateway_offline = 'GATEWAY_OFFLINE',
  get_gateway_property = 'GET_GATEWAY_PROPERTY',
  get_node_property = 'GET_NODE_PROPERTY',
  node_ready_trap = 'NODE_READY_TRAP',
  get_gateway_network_diagnostic_info = 'GET_GATEWAY_NETWORK_DIAGNOSTIC_INFO',
  get_neb_looging_already_enabled = 'NEB_LOGGING_ALREADY_ENABLED',
  get_node_network_diagnostic_info = 'GET_NODE_NETWORK_DIAGNOSTIC_INFO',
  get_ncp_gateway_connection_detail = 'GET_NCP_GATEWAY_CONNECTION_DETAIL',
  get_node_connection_detail = 'GET_NODE_CONNECTION_DETAIL',
  get_ncp_gateway_log_count = 'GET_NCP_GATEWAY_LOG_COUNT',
  get_ncp_gateway_log_by_id = 'GET_NCP_GATEWAY_LOG_BY_ID',
  set_node_dump_log_by_id = 'SET_NODE_DUMP_LOG_BY_ID',
  set_node_log = 'SET_NODE_LOG',
  get_node_log_count = 'GET_NODE_LOG_COUNT',
  get_node_log_by_id = 'GET_NODE_LOG_BY_ID',
  node_parent_switch_trap = 'NODE_PARENT_SWITCH_TRAP',
  set_ncp_gateway_log = 'SET_NCP_GATEWAY_LOG',
  set_gateway_log = 'SET_GATEWAY_LOG',
  gateway_ready_trap = 'GATEWAY_READY_TRAP',
  gateway_ready_trap_v2 = 'GATEWAY_READY_TRAP_V2',
  get_gateway_ping = 'GET_GATEWAY_PING',
  get_node_ping = 'GET_NODE_PING',
  set_ncp_gateway_dump_all_logs = 'SET_NCP_GATEWAY_DUMP_ALL_LOGS',
  set_node_dump_all_logs = 'SET_NODE_DUMP_ALL_LOGS',
  node_status_trap = 'NODE_STATUS_TRAP',
  set_gateway_dump_all_logs = 'SET_GATEWAY_DUMP_ALL_LOGS',
  get_gateway_log_count = 'GET_GATEWAY_LOG_COUNT',
  get_dcu_property = 'GATEWAY_PROPERTY_TRAP', //GATEWAY_PROPERTY_TRAP
  get_power_status = 'GATEWAY_POWER_STATUS_TRAP',
  get_dcu_power_status = 'GET_DCU_POWER_STATUS',
  get_device_list = 'GET_DEVICE_LIST',
  node_network_diagnostic_info_trap = 'NODE_NETWORK_DIAGNOSTIC_INFO_TRAP',
  gateway_network_diagnostic_info_trap = 'GATEWAY_NETWORK_DIAGNOSTIC_INFO_TRAP',
  gateway_network_diagnostic_info_trap_v2 = 'GATEWAY_NETWORK_DIAGNOSTIC_INFO_TRAP_V2',
  gateway_ping_pong_trap = 'GATEWAY_PING_PONG_TRAP',
  set_gateway_unix_time = 'SET_GATEWAY_UNIX_TIME',
  set_node_unix_time = 'SET_NODE_UNIX_TIME',
  get_node_factory_reset = 'GET_NODE_FACTORY_RESET',
  get_node_reboot = 'GET_NODE_REBOOT',
  get_gateway_fota_status_trap = 'GATEWAY_FOTA_STATUS_TRAP',
  gateway_battery_config_trap = 'GATEWAY_BATTERY_CONFIG_TRAP',
  gateway_error_trap = 'GATEWAY_ERROR_TRAP',
  gateway_log_config_trap = 'GATEWAY_LOG_CONFIG_TRAP',
  gateway_server_config_trap = 'GATEWAY_SERVER_CONFIG_TRAP',
  gateway_server_config_trap_v3 = 'GATEWAY_SERVER_CONFIG_TRAP_V3',
  gateway_tamper_trap = 'GATEWAY_TAMPER_TRAP',
  gateway_wisun_config_trap = 'GATEWAY_WISUN_CONFIG_TRAP',
  gateway_wisun_config_trap_v2 = 'GATEWAY_WISUN_CONFIG_TRAP_V2',
  get_gateway_battery_config = 'GET_GATEWAY_BATTERY_CONFIG',
  get_gateway_factory_reset = 'GET_GATEWAY_FACTORY_RESET',
  get_gateway_log_config = 'GET_GATEWAY_LOG_CONFIG',
  get_gateway_reboot = 'GET_GATEWAY_REBOOT',
  get_gateway_server_config = 'GET_GATEWAY_SERVER_CONFIG',
  get_gateway_unix_time = 'GET_GATEWAY_UNIX_TIME',
  get_gateway_version = 'GET_GATEWAY_VERSION',
  get_gateway_wisun_config = 'GET_GATEWAY_WISUN_CONFIG',
  get_meter_config = 'GET_METER_CONFIG',
  get_node_config = 'GET_NODE_CONFIG',
  get_node_log_config = 'GET_NODE_LOG_CONFIG',
  get_node_ota_config = 'GET_NODE_OTA_CONFIG',
  get_node_server_config = 'GET_NODE_SERVER_CONFIG',
  get_node_unix_time = 'GET_NODE_UNIX_TIME',
  get_node_version = 'GET_NODE_VERSION',
  get_node_wisun_config = 'GET_NODE_WISUN_CONFIG',
  node_network_diagnostic_info_trap_v3 = 'NODE_NETWORK_DIAGNOSTIC_INFO_TRAP_V3',
  node_offline = 'NODE_OFFLINE',
  node_ota_dfu_notify_trap = 'NODE_OTA_DFU_NOTIFY_TRAP',
  node_power_fail_trap = 'NODE_POWER_FAIL_TRAP',
  node_ready_trap_v2 = 'NODE_READY_TRAP_V2',
  node_ready_trap_v3 = 'NODE_READY_TRAP_V3',
  node_time_sync_trap = 'NODE_TIME_SYNC_TRAP',
  repeater_power_status_trap = 'REPEATER_POWER_STATUS_TRAP',
  set_gateway_battery_config = 'SET_GATEWAY_BATTERY_CONFIG',
  set_gateway_fota = 'SET_GATEWAY_FOTA',
  set_gateway_log_config = 'SET_GATEWAY_LOG_CONFIG',
  set_gateway_pan_id_reset = 'SET_GATEWAY_PAN_ID_RESET',
  set_gateway_server_config = 'SET_GATEWAY_SERVER_CONFIG',
  set_gateway_server_config_v3 = 'SET_GATEWAY_SERVER_CONFIG_V3',
  set_gateway_wisun_config = 'SET_GATEWAY_WISUN_CONFIG',
  set_node_config = 'SET_NODE_CONFIG',
  set_node_fota = 'SET_NODE_FOTA',
  set_node_log_config = 'SET_NODE_LOG_CONFIG',
  set_node_meter_config = 'SET_NODE_METER_CONFIG',
  set_node_ota_config = 'SET_NODE_OTA_CONFIG',
  set_node_server_config = 'SET_NODE_SERVER_CONFIG',
  set_node_wisun_config = 'SET_NODE_WISUN_CONFIG',
  gateway_ready_trap_v4 = 'GATEWAY_READY_TRAP_V4',
  gateway_wisun_config_trap_v4 = 'GATEWAY_WISUN_CONFIG_TRAP_V4',
  gateway_property_trap_v5 = 'GATEWAY_PROPERTY_TRAP_V5',
  gateway_server_config_trap_v5 = 'GATEWAY_SERVER_CONFIG_TRAP_V5',
  node_ready_trap_v5 = 'NODE_READY_TRAP_V5',
  node_network_diagnostic_info_trap_v5 = 'NODE_NETWORK_DIAGNOSTIC_INFO_TRAP_V5'
}

@Injectable({
  providedIn: 'root'
})

export class MqttSubscribeService {
  mqttConnection = this.mqttService.onConnect;
  @Output() notifyMqtt = new EventEmitter<PublishModel>();
  dateObservable: Observable<PublishModel>;
  isMqttConnected = false;
  subscriptionAll: any;
  constructor(private mqttService: MqttService, private toastr: ToastrService, private loginService: LoginService,) { }
  connectMqtt() {
    if (JSON.parse(localStorage.getItem('access_token'))) {
      var client = JSON.parse(localStorage.getItem('access_token')).access_token;
      var clients = client.split(".")[2]
    }
    let MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
      hostname: apiConfig.mqttHostname,
      port: apiConfig.mqttPort,
      protocol: 'ws',
      clientId: clients,
    };
    if (apiConfig.mqttProtocol === 'wss') {
      MQTT_SERVICE_OPTIONS.protocol = 'wss';
    }

    this.mqttService.connect(MQTT_SERVICE_OPTIONS);
    this.mqttService.onConnect.subscribe((connack) => {
      console.log('CONNECTED');
      this.isMqttConnected = true;
      console.log(connack);
    });

    this.mqttService.onError.subscribe((err) => {
    });
  }


  disConnect() {
    this.mqttService.disconnect();
    this.isMqttConnected = false;
  }
  subscription: any;
  publishModelError: PublishModelError;
  //#region "Response Type Wise Emitters"
  @Output() gatewaySystemTrap = new EventEmitter<any>();
  @Output() gatewayLogCount = new EventEmitter<PublishModel>();
  @Output() gatewayReboot = new EventEmitter<PublishModel>();
  @Output() gatewayRemoveDevice = new EventEmitter<PublishModel>();
  @Output() getGatewayProperty = new EventEmitter<PublishModel>();
  @Output() getGatewayPing = new EventEmitter<PublishModel>();
  @Output() setNcpGatewayDumpAllLogs = new EventEmitter<PublishModel>();
  @Output() getNcpGatewayLogById = new EventEmitter<PublishModel>();
  @Output() setNcpGatewayLog = new EventEmitter<PublishModel>();
  @Output() setGatewayLog = new EventEmitter<PublishModel>();
  @Output() nodeParentSwitchTrap = new EventEmitter<PublishModel>();
  @Output() getGatewayDiagnosticInfo = new EventEmitter<PublishModel>();
  //#endregion "Response Type Wise Emitters"
  @Output() deviceLogCount = new EventEmitter<PublishModel>();
  @Output() deviceReboot = new EventEmitter<PublishModel>();
  @Output() deviceConnectionDetails = new EventEmitter<PublishModel>();
  @Output() getDeviceProperty = new EventEmitter<PublishModel>();
  @Output() getDevicePing = new EventEmitter<PublishModel>();
  @Output() setDeviceDumpAllLogs = new EventEmitter<PublishModel>();
  @Output() getDeviceLogById = new EventEmitter<PublishModel>();
  @Output() setDeviceLog = new EventEmitter<PublishModel>();
  @Output() getDeviceDiagnosticInfo = new EventEmitter<PublishModel>();
  @Output() nodeStatusTrap = new EventEmitter<PublishModel>();
  @Output() errorTrap = new EventEmitter<PublishModel>();
  @Output() setGatewayDumpAllLogs = new EventEmitter<PublishModel>();
  @Output() getDcuProperties = new EventEmitter<PublishModel>();
  @Output() gatewayPowerStatusTrap = new EventEmitter<PublishModel>();
  @Output() getGatewayDevicesListTrap = new EventEmitter<PublishModel>();
  @Output() nodeNetworkDiagnosticInfoTrap = new EventEmitter<PublishModel>();
  @Output() gatewayNetworkDiagnosticInfoTrap = new EventEmitter<PublishModel>();
  @Output() gatewaypingpongtrap = new EventEmitter<PublishModel>();
  @Output() setGatewayUnixTime = new EventEmitter<PublishModel>();
  @Output() getGatewayOffline = new EventEmitter<PublishModel>();
  @Output() getDeviceList = new EventEmitter<PublishModel>();
  @Output() nodeUnixTime = new EventEmitter<PublishModel>();
  @Output() getNodeFactoryReset = new EventEmitter<PublishModel>();
  @Output() getNodeReboot = new EventEmitter<PublishModel>();
  @Output() gatewayNetworkDiagnosticInfoTrapv2 = new EventEmitter<PublishModel>();
  @Output() gatewayBatteryConfigTrap = new EventEmitter<PublishModel>();
  @Output() gatewayErrorTrap = new EventEmitter<PublishModel>();
  @Output() gatewayLogComfigTrap = new EventEmitter<PublishModel>();
  @Output() gatewayReadyTrap = new EventEmitter<PublishModel>();
  @Output() gatewayReadyTrapV2 = new EventEmitter<PublishModel>();
  @Output() gatewayServerConfigTrap = new EventEmitter<PublishModel>();
  @Output() gatewayServerConfigTrapV3 = new EventEmitter<PublishModel>();
  @Output() gatewayTamperTrap = new EventEmitter<PublishModel>();
  @Output() gatewayWisunConfigTrap = new EventEmitter<PublishModel>();
  @Output() gatewayWisunConfigTrapV2 = new EventEmitter<PublishModel>();
  @Output() getGatewayBatteryConfig = new EventEmitter<PublishModel>();
  @Output() getGatewayFactoryReset = new EventEmitter<PublishModel>();
  @Output() getGatewayLogConfig = new EventEmitter<PublishModel>();
  @Output() getGatewayReboot = new EventEmitter<PublishModel>();
  @Output() getGatewayServerConfig = new EventEmitter<PublishModel>();
  @Output() getGatewayUnixTime = new EventEmitter<PublishModel>();
  @Output() getGatewayVersion = new EventEmitter<PublishModel>();
  @Output() getGatewayWisunConfig = new EventEmitter<PublishModel>();
  @Output() getMeterConfig = new EventEmitter<PublishModel>();
  @Output() getNodeConfig = new EventEmitter<PublishModel>();
  @Output() getNodeLogConfig = new EventEmitter<PublishModel>();
  @Output() getNodeOTAConfig = new EventEmitter<PublishModel>();
  @Output() getNodeServerConfig = new EventEmitter<PublishModel>();
  @Output() getNodeUnixTime = new EventEmitter<PublishModel>();
  @Output() getNodeVersion = new EventEmitter<PublishModel>();
  @Output() getNodeWisunConfig = new EventEmitter<PublishModel>();
  @Output() nodeNetworkDiagnosticInfoTrapV3 = new EventEmitter<PublishModel>();
  @Output() nodeOffline = new EventEmitter<PublishModel>();
  @Output() nodeOtaDfuNotifyTrap = new EventEmitter<PublishModel>();
  @Output() nodePowerFailTrap = new EventEmitter<PublishModel>();
  @Output() nodeReadyTrapV2 = new EventEmitter<PublishModel>();
  @Output() nodeReadyTrapV3 = new EventEmitter<PublishModel>();
  @Output() nodeTimeSyncTrap = new EventEmitter<PublishModel>();
  @Output() repeaterPowerStatusTrap = new EventEmitter<PublishModel>();
  @Output() setGatewayBatteryConfig = new EventEmitter<PublishModel>();
  @Output() setGatewayFota = new EventEmitter<PublishModel>();
  @Output() setGatewayLogConfig = new EventEmitter<PublishModel>();
  @Output() setGatewayPanIdReset = new EventEmitter<PublishModel>();
  @Output() setGatewayServerConfig = new EventEmitter<PublishModel>();
  @Output() setGatewayServerConfigV3 = new EventEmitter<PublishModel>();
  @Output() setGatewayWisunConfig = new EventEmitter<PublishModel>();
  @Output() setNodeConfig = new EventEmitter<PublishModel>();
  @Output() setNodeFota = new EventEmitter<PublishModel>();
  @Output() setNodeLogConfig = new EventEmitter<PublishModel>();
  @Output() setNodeMeterConfig = new EventEmitter<PublishModel>();
  @Output() setNodeOtaConfig = new EventEmitter<PublishModel>();
  @Output() setNodeServerConfig = new EventEmitter<PublishModel>();
  @Output() setNodeWisunConfig = new EventEmitter<PublishModel>();
  @Output() gatewayReadyTrapV4 = new EventEmitter<PublishModel>();
  @Output() gatewayWisunConfigTrapV4 = new EventEmitter<PublishModel>();
  @Output() gatewayPropertyTrapV5 = new EventEmitter<PublishModel>();
  @Output() gatewayServerConfigTrapV5 = new EventEmitter<PublishModel>();
  @Output() nodeReadyTrapV5 = new EventEmitter<PublishModel>();
  @Output() nodeNetworkDiagnosticInfoTrapV5 = new EventEmitter<PublishModel>();
  subscribe(nodeId) {
    const application_token = localStorage.getItem(LocalStorageKeys.application_token);
    const topic = MqttPortalTopicPrefix.replace('{app_token}', application_token).replace('{node_id}', nodeId);
    this.SubScribeTopic(topic).subscribe(response => {
      console.log(response);
      this.handleResponse(response);
    });
  }
  subscribeForAllGateways() {
    const application_token = localStorage.getItem(LocalStorageKeys.application_token);
    const topic = MqttPortalTopicPrefix.replace('{app_token}', application_token).replace('{node_id}', '#');
    this.SubScribeTopic(topic).subscribe(response => {
      this.handleResponse(response);
    });
  }

  unsubscribe() {
    console.log('unsubscribe',);
    this.subscription ? this.subscription.unsubscribe() : null;

  }
  public SubScribeTopic(topic): Observable<PublishModel> {
    this.dateObservable = new Observable<PublishModel>(observer => {
      console.log('SubScribeTopic(topic) : subscribing to ' + topic);
      this.subscription = this.mqttService.observe(topic).subscribe((message: IMqttMessage) => {
        console.log('mqtt receive : ' + message.payload.toString());
        const jsonData: PublishModel = JSON.parse(message.payload.toString());

        if (jsonData) {
          observer.next(jsonData);
        }

      }, (err) => {
        console.log(err);
      });
    });
    return this.dateObservable;
  }
  handleResponse(response: any) {
    if (response.isSystemMsg) {
      this.gatewaySystemTrap.emit(response);
    }
    if (response.reqType !== null) {

      // based on response type
      switch (response.reqType) {
        case PayloadType.get_ncp_gateway_log_count:
          this.gatewayLogCount.emit(response);
          break;
        case PayloadType.gateway_ready_trap:
          this.gatewayReboot.emit(response);
          break;
        case PayloadType.get_gateway_property:
          this.getGatewayProperty.emit(response);
          break;
        case PayloadType.get_gateway_ping:
          this.getGatewayPing.emit(response);
          break;
        case PayloadType.set_ncp_gateway_dump_all_logs:
          this.setNcpGatewayDumpAllLogs.emit(response);
          break;
        case PayloadType.get_ncp_gateway_log_by_id:
          this.getNcpGatewayLogById.emit(response);
          break;
        case PayloadType.get_gateway_network_diagnostic_info:
          this.getGatewayDiagnosticInfo.emit(response);
          break;
        case PayloadType.set_ncp_gateway_log:
          this.setNcpGatewayLog.emit(response);
          break;
        case PayloadType.set_gateway_log:
          this.setGatewayLog.emit(response);
          break;
        case PayloadType.node_parent_switch_trap:
          this.nodeParentSwitchTrap.emit(response);
          break;
        case PayloadType.set_gateway_unix_time:
          this.setGatewayUnixTime.emit(response);
          break;
        case PayloadType.get_node_log_count:
          this.deviceLogCount.emit(response);
          break;
        case PayloadType.node_ready_trap:
          this.deviceReboot.emit(response);
          break;
        case PayloadType.get_node_connection_detail:
          this.deviceConnectionDetails.emit(response);
          break;
        case PayloadType.get_node_property:
          this.getDeviceProperty.emit(response);
          break;
        case PayloadType.get_node_ping:
          this.getDevicePing.emit(response);
          break;
        case PayloadType.set_node_dump_all_logs:
          this.setDeviceDumpAllLogs.emit(response);
          break;
        case PayloadType.get_node_log_by_id:
          this.getDeviceLogById.emit(response);
          break;
        case PayloadType.get_node_network_diagnostic_info:
          this.getDeviceDiagnosticInfo.emit(response);
          break;
        case PayloadType.set_node_log:
          this.setDeviceLog.emit(response);
          break;
        case PayloadType.node_status_trap:
          this.nodeStatusTrap.emit(response);
          break;
        case PayloadType.set_gateway_dump_all_logs:
          this.setGatewayDumpAllLogs.emit(response);
          break;
        case PayloadType.get_gateway_log_count:
          this.gatewayLogCount.emit(response);
          break;
        case PayloadType.get_dcu_property:
          this.getDcuProperties.emit(response);
          break;
        case PayloadType.get_power_status:
          this.gatewayPowerStatusTrap.emit(response);
          break;
        case PayloadType.get_device_list:
          this.getGatewayDevicesListTrap.emit(response);
          break;
        case PayloadType.node_network_diagnostic_info_trap:
          this.nodeNetworkDiagnosticInfoTrap.emit(response);
          break;
        case PayloadType.gateway_network_diagnostic_info_trap:
          this.gatewayNetworkDiagnosticInfoTrap.emit(response);
          break;
        case PayloadType.gateway_ping_pong_trap:
          this.gatewaypingpongtrap.emit(response);
          break;
        case PayloadType.set_node_unix_time:
          this.nodeUnixTime.emit(response);
          break;
        case PayloadType.get_gateway_offline:
          this.getGatewayOffline.emit(response);
          break;
        case PayloadType.get_node_factory_reset:
          this.getNodeFactoryReset.emit(response);
          break;
        case PayloadType.get_node_reboot:
          this.getNodeReboot.emit(response);
          break;
        case PayloadType.gateway_network_diagnostic_info_trap_v2:
          this.gatewayNetworkDiagnosticInfoTrapv2.emit(response);
          break;
        case PayloadType.gateway_battery_config_trap:
          this.gatewayBatteryConfigTrap.emit(response);
          break;
        case PayloadType.gateway_error_trap:
          this.gatewayErrorTrap.emit(response);
          break;
        case PayloadType.gateway_log_config_trap:
          this.gatewayLogComfigTrap.emit(response);
          break;
        case PayloadType.gateway_server_config_trap:
          this.gatewayServerConfigTrap.emit(response);
          break;
        case PayloadType.gateway_server_config_trap_v3:
          this.gatewayServerConfigTrapV3.emit(response);
          break;
        case PayloadType.gateway_tamper_trap:
          this.gatewayTamperTrap.emit(response);
          break;
        case PayloadType.gateway_wisun_config_trap:
          this.gatewayWisunConfigTrap.emit(response);
          break;
        case PayloadType.gateway_wisun_config_trap_v2:
          this.gatewayWisunConfigTrapV2.emit(response);
          break;
        case PayloadType.get_gateway_battery_config:
          this.getGatewayBatteryConfig.emit(response);
          break;
        case PayloadType.get_gateway_factory_reset:
          this.getGatewayFactoryReset.emit(response);
          break;
        case PayloadType.get_gateway_log_config:
          this.getGatewayLogConfig.emit(response);
          break;
        case PayloadType.get_gateway_reboot:
          this.getGatewayReboot.emit(response);
          break;
        case PayloadType.get_gateway_server_config:
          this.getGatewayServerConfig.emit(response);
          break;
        case PayloadType.get_gateway_unix_time:
          this.getGatewayUnixTime.emit(response);
          break;
        case PayloadType.get_gateway_version:
          this.getGatewayVersion.emit(response);
          break;
        case PayloadType.get_gateway_wisun_config:
          this.getGatewayWisunConfig.emit(response);
          break;
        case PayloadType.get_meter_config:
          this.getMeterConfig.emit(response);
          break;
        case PayloadType.get_node_config:
          this.getNodeConfig.emit(response);
          break;
        case PayloadType.get_node_log_config:
          this.getNodeLogConfig.emit(response);
          break;
        case PayloadType.get_node_ota_config:
          this.getNodeOTAConfig.emit(response);
          break;
        case PayloadType.get_node_server_config:
          this.getNodeServerConfig.emit(response);
          break;
        case PayloadType.get_node_unix_time:
          this.getNodeUnixTime.emit(response);
          break;
        case PayloadType.get_node_version:
          this.getNodeVersion.emit(response);
          break;
        case PayloadType.get_node_wisun_config:
          this.getNodeWisunConfig.emit(response);
          break;
        case PayloadType.node_network_diagnostic_info_trap_v3:
          this.nodeNetworkDiagnosticInfoTrapV3.emit(response);
          break;
        case PayloadType.node_offline:
          this.nodeOffline.emit(response);
          break;
        case PayloadType.node_ota_dfu_notify_trap:
          this.nodeOtaDfuNotifyTrap.emit(response);
          break;
        case PayloadType.node_power_fail_trap:
          this.nodePowerFailTrap.emit(response);
          break;
        case PayloadType.node_ready_trap_v2:
          this.nodeReadyTrapV2.emit(response);
          break;
        case PayloadType.node_ready_trap_v3:
          this.nodeReadyTrapV3.emit(response);
          break;
        case PayloadType.node_time_sync_trap:
          this.nodeTimeSyncTrap.emit(response);
          break;
        case PayloadType.repeater_power_status_trap:
          this.repeaterPowerStatusTrap.emit(response);
          break;
        case PayloadType.set_gateway_battery_config:
          this.setGatewayBatteryConfig.emit(response);
          break;
        case PayloadType.set_gateway_fota:
          this.setGatewayFota.emit(response);
          break;
        case PayloadType.set_gateway_log_config:
          this.setGatewayLogConfig.emit(response);
          break;
        case PayloadType.set_gateway_pan_id_reset:
          this.setGatewayPanIdReset.emit(response);
          break;
        case PayloadType.set_gateway_server_config:
          this.setGatewayServerConfig.emit(response);
          break;
        case PayloadType.set_gateway_server_config_v3:
          this.setGatewayServerConfigV3.emit(response);
          break;
        case PayloadType.set_gateway_wisun_config:
          this.setGatewayWisunConfig.emit(response);
          break;
        case PayloadType.set_node_config:
          this.setNodeConfig.emit(response);
          break;
        case PayloadType.set_node_fota:
          this.setNodeFota.emit(response);
          break;
        case PayloadType.set_node_log_config:
          this.setNodeLogConfig.emit(response);
          break;
        case PayloadType.set_node_meter_config:
          this.setNodeMeterConfig.emit(response);
          break;
        case PayloadType.set_node_ota_config:
          this.setNodeOtaConfig.emit(response);
          break;
        case PayloadType.set_node_server_config:
          this.setNodeServerConfig.emit(response);
          break;
        case PayloadType.set_node_wisun_config:
          this.setNodeWisunConfig.emit(response);
          break;
        case PayloadType.gateway_ready_trap_v4:
          this.gatewayReadyTrapV4.emit(response);
          break;
        case PayloadType.gateway_wisun_config_trap_v4:
          this.gatewayWisunConfigTrapV4.emit(response);
          break;
        case PayloadType.gateway_property_trap_v5:
          this.gatewayPropertyTrapV5.emit(response);
          break;
        case PayloadType.gateway_server_config_trap_v5:
          this.gatewayServerConfigTrapV5.emit(response);
          break;
        case PayloadType.node_ready_trap_v5:
          this.nodeReadyTrapV5.emit(response);
          break;
        case PayloadType.node_network_diagnostic_info_trap_v5:
          this.nodeNetworkDiagnosticInfoTrapV5.emit(response);
          break;
        default:
          console.warn('Response type wise not handled' + response.reqType);
          break;
      }
    } else {
      this.errorTrap.emit(response);
      console.warn(response.payload.errorInfo);

    }

  }
}

