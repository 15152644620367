export const flatten = filter => {
    const filters = (filter || {}).filters;
    if (filters) {
        return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
    }
    return [];
};

export enum NotificationMessageTitle {
    Success = 'Success',
    Error = 'Error',
    Warning = 'Warning',
}



export enum LocalStorageKeys {
    nodestatecon = 'nodestatecon',
    nodeStatusId = 'nodeStatusId',
    user = 'user',
    access_token = 'access_token',
    application_token = 'application_tokenid',
    node_id = 'node_id',
    ncpMac = 'ncpMac',
    deviceId = 'deviceId',
    hostEvent = 'hostEvent',
    ncpEvent = 'ncpEvent',
    deviceEvent = 'deviceEvent',
    gatewayStatus = 'gatewayStatus',
    deviceStatus = 'deviceStatus',
    nodeStatus = 'nodeStatus',
    repeaterStatus = 'repeaterStatus',
    gatewayLogging = 'gatewayLogging',
    nodeLogging = 'nodeLogging',
    tabName = 'tabName',
    deviceTabName = 'deviceTabName',
    fota_access_token = 'fota_access_token',
    isFotaSubscribe = "isFotaSubscribe",
    applications = "applications",
    authFaildDeviceStatus = 'AuthFaildDetailsDeviceType'
}

export enum UrlParameter {
    GrantTypePassword = "GrantTypePassword",
    UserName = "UserName",
    Password = "Password",
    ClientIdValue = "ClientIdValue",
    ClientSecretValue = "ClientSecretValue",
    starttime = 'starttime',
    endtime = 'endtime',
    nodestatus = 'nodestatus',
    pageStartingIndex = 'pageStartingIndex',
    pageEndingIndex = 'pageEndingIndex',
    description = 'description',
    repeater = 'repeater',
    tokenId = 'tokenId',
    limit = 'limit',
    offset = 'offset',
    orderBy = 'orderBy',
    orderColumn = 'orderColumn',
    search = 'search',
    draw = 'draw',
    status = 'status',
    nodeId = 'nodeId',
    deviceId = 'deviceId',
    mac = 'mac',
    endNodeDeviceId = 'endNodeDeviceId',
    userId = 'userId',
    groupId = 'groupId',
    groupName = 'groupName',
    artifactId = 'artifactId',
    deploymentId = 'deploymentId',
    btnId = 'btnId',
    lblValue = 'lblValue',
    searchParameter = 'searchParameter',
    name = 'name',
    deviceName = 'deviceName',
    type = 'type',
    errorEvent = 'errorEvent',
    interfaceValue = 'interfaceValue',
    version = 'version'
}

export enum PageTitle {
    addPageTitle = 'Add',
    editPageTitle = 'Edit',
}

export enum gatewayRebootReason {
    Unknow = "Unknow Reason",
    userReset = "User Reset Device",
    factoryReset = "Factory Reset",
    firmwareUpdateReset = "firware update reset",
    brownOutReset = "brownout reset",
    watchDogReset = "watchdog reset"
}

export enum loggingStatus {
    Enable = 'ENABLE',
    Disable = 'DISABLE',
}
export enum loggingStatusName {
    Enable = 'Enable',
    Disable = 'Disable',
}
export enum userRole {
    Integrator = 'INTEGRATOR',
    Operator = 'OPERATOR',
    Analysis = 'ANALYSIS'
}

export enum AlertTitle {
    Error = 'Error !',
    Success = 'Success',
    ConfirmAlert = 'Are you sure?',
    Errors = 'Server Error !',
    severMessage = 'No response from API Server.\nContact Server administrator.'

}
export enum AlertType {
    Error = 'error',
    Success = 'success',
    Warning = 'warning',
}

export enum AlertMessage {
    DataNotFound = 'Data not found',
    Dateselect = 'Please select Date',
    DevicePropertiesUpdateSuccess = 'Device Properties updated successfully',
    DeviceReady = 'Device is Rebooted',
    SomethingWentWrong = 'Something went wrong!! Please try again',
    LogRequestSuccess = ' Log request successfully..',
    LogCount = 'Device Log Count',
    EraseLogConfirm = ' Do You want to erase log ?.',
    WontRevertMessage = 'You won\'t be able to revert this',
    DeviceResetSuccess = 'Device reset successfully',
    DeviceLogsDumpSuccess = 'Device logs dumpped',
    LogDownloading = 'Log downloading..',
    ConfirmUpdateBR = 'Do you want to update Border Router ? ',
    UpdateBRSuccess = ' Border Router detail updated successfully',
    UpdatedBRPropertiesSuccess = 'Properties updated Successfully',
    BRReadySuccess = 'Border Router is Rebooted',
    TimesyncSuccess = 'Time Synchronization Successfully',
    DeviceRemovedSuccess = 'Device Removed ',
    BRAddSuceess = 'Border Router added successfully',
    BRLogCountSuccess = 'Border Router Log Count',
    BRLogDumpsuccess = 'Border Router  logs dumpped',
    ConfirmLogLevelUpdate = 'Do you want to update Log Level ?',
    BRResetSuccess = 'Border Router  reset successfully',
    ACksuccess = 'Acknowledge successfully',
    NodeParentNotFound = 'Node Parents not found',
    DeviceDisconnectSuccess = 'Device disconnected successfully.',
    InvalidFile = 'Invalid file type.Please upload valid csv file.',
    FileTitleInvalid = 'File title must be deviceId',
    FileEmpty = 'Data is not available in CSV File',
    ConfirmUpdatePreloadDevice = 'Do you want to update Preloaded Device ?',
    UpdatePreloadedDeviceSuccess = 'Updated Preloaded device successfully',
    AddPreloadedDeviceSuccess = 'Added Preloaded device successfully',
    DeviceDeleteSuccess = 'Device delete successfully.',
    UpdateConfiguration = 'Update Configuration',
    Description = 'Description should not be blank',
    DeviceId = 'DeviceId should not be blank',
    name = 'Name should not be blank',
    borderRouter = 'Please Select Border Router',
    node = 'Please Select Node',
    confirmAddBR = 'Do you want to add Border Router ?',
    confirmAddPreloadDevice = 'Do you want to add Preloaded Device ?',
    getDeviceConfigData = 'Configuration got',
    deviceNonCommissioned = 'Status Change Successfully',
    getDeviceListRequest = 'Device List Get Successfully',
    panIdReset = 'Pan-Id Reset Successfully',
    stopDeployment = 'Stop Deployment Successfully',
    getVersion = 'Version got',
    dateMessage = 'Date Is Invalid Range',
    versionMessage = 'Please Select Version',
    interfaceValueMessage = 'Please Select Interface',
    tunValue = 'Please Select tun Value',


}

export enum MQTTTopicParameters {
    TokenId = 'tokenId',
    NodeId = 'nodeId',
    SensorId = 'sensorId',
    UserId = 'userId',
    type = 'type'
}

export enum AlertButton {
    ConfirmButtonReset = 'Yes, Reset it!',
    ConfirmButtonReboot = 'Yes, Reboot it!',
    ConfirmButtonUpdate = 'Yes, Update it!',
    ConfirmButtonAdd = 'Yes, Add it!',
    ConfirmButtonDelete = 'Yes, Delete it!',
    ConfirmButtonACKAll = 'Yes, Acknowledge All!',
    ConfirmButtonACK = 'Yes, Acknowledge it!',
    ConfirmButtonsync = 'Yes, Sync it!',
    confirmButtonChange = 'Yes'

}
export enum AlertButtonColor {
    ConfirmButtonColor = '#3085d6',
    CancelButtonColor = '#d33',
}

export enum DateFormats {
    longDateWithTime = 'dd/MM/yyyy hh:mm:ss a',
    momentDateWithTime = 'DD/MM/yyyy hh:mm:ss A',
    dateFormatMMDDYY = 'MM/DD/yyyy hh:mm:ss a',
    dateFormatShort = 'M/D/YYYY H:mm A'
}

export enum BorderRouterStatus {
    connected = '1',
    disconnected = '2',
    all = '3'
}

export enum NodeStatus {
    whiteList = '',
    connected = '1',
    disconnected = '2',
    nonCommissioned = '4',
    all = '3'
}

export enum BatteryStatus {
    batteryNotCharged = 'Battery not Charging (Battery Not present)',
    batteryLower = 'Battery Discharging',
    batteryCharged = 'Battery Charging',
    discharged = 'Battery Discharged'
}

export enum DeviceStatus {
    registered = 'REGISTERED',
    connected = 'CONNECTED',
    disconnected = 'DISCONNECTED',
    removed = 'REMOVED',
    unauthorized = 'UNAUTHORIZED',
    whiteListed = 'WHITELISTED',
    nonCommissioned = 'NON_COMMISSIONED'
}

export enum PowerStatus {
    powerSupply = 'on Power Supply',
    batterySupply = 'on Battery Supply'

}

export enum DeviceType {
    sixLopan = '6LoWPAN',
    ncp = 'NCP',
    Zigbee = 'Zigbee',
    main = 'Main',
    host = 'Host',
    rF = 'RF',

}

export enum InterfaceName {
    systemInfo = 'SystemInfo',
    ppp0 = 'ppp0',
    eth0 = 'eth0',
    wlan0 = 'wlan0',
    others = 'others'

}

export enum ConfigurationName {
    serverConfiguration = 'ServerConfigInfo',
    batteryConfiguration = 'batteryConfig',
    wisunConfiguration = 'wisunConfig',
    logConfiguration = 'logConfig'


}

export enum selectConfigTab {
    serverConfigTab = 1,
    batteryConfigTab = 2,
    wisunConfigTab = 3,
    logConfigTab = 4


}

export enum authFaildDeviceType {
    all = '0',
    borderRouter = '1',
    node = '2',
    unregisterNode = '3'
}


