import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateAgoPipe } from '../../common/date-ago.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [DateAgoPipe],
    exports: [DateAgoPipe], 
})
export class SharedPipesModule {}
