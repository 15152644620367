import { Component } from '@angular/core';

import { MqttSubscribeService } from './core/services/mqtt-subscribe.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private mqttService:MqttSubscribeService,private router:Router) {}

    ngOnInit() {
        // const isLogin = JSON.parse(localStorage.getItem('user'));
        // if(isLogin){
        //     this.mqttService.connectMqtt();
        // }
    }
}
