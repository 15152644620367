import { UrlParameter } from './constant';

declare var apiConfig: any;


export class ApiUrls {
  get UrlParameter() { return UrlParameter; }
  static baseUrl: string = apiConfig.WebAPIBaseUrl;
  static fotaBaseUrl: string = apiConfig.FotaWebAPIBaseUrl;
  static terminalBaseUrl: string = apiConfig.rttyTerminalUrl;

  // User Module
  static loginUrl = `${ApiUrls.baseUrl}nebulae/api/auth/v1/token`;
  static registerUrl = `${ApiUrls.baseUrl}nebulae/userRegisterActivity`;
  static termsUrl = `${ApiUrls.baseUrl}nebulae/api/v1/userRegisterActivity/termsandconditions`;
  static getTokenIdUrl = `${ApiUrls.baseUrl}nebulae/api/v1/user/applications`;
  static getUserDataUrl = `${ApiUrls.baseUrl}nebulae/api/v1/user/whoami`;
  static allGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/userNodes/${UrlParameter.tokenId}/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allGatewaysV2 = `${ApiUrls.baseUrl}nebulae/api/nms/userNodes/list/${UrlParameter.tokenId}/${UrlParameter.version}`;
  static gatewayProperties = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/detail/${UrlParameter.nodeId}`;
  static allGatewaysWisun = `${ApiUrls.baseUrl}nebulae/api/nms/wisun/userNodes/list/${UrlParameter.tokenId}/${UrlParameter.version}/${UrlParameter.interfaceValue}`;
  static addBorderRouter = `${ApiUrls.baseUrl}nebulae/api/v1/node`;

  static gatewayDcuTimelineUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/timegraph/${UrlParameter.nodeId}`;

  static dashboardUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dashboard`;
  static dashboardBorderRouterFwUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/firmware/count`;
  static dashboardEndNodeDeviceFwUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/firmware/count`;
  static dashboardEndNodeRepeaterFwUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/repeater/firmware/count`;
  static dashboardMapData = `${ApiUrls.baseUrl}nebulae/api/v1/nms/map`;
  static gatewaytimesyncUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/unix/time`;
  static gatewayRebootUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/reboot`;
  static gatewayFactoryRestUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/factory/reset`;
  static gatewayPingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/ping`;
  static gatewayDumpAllUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/dump/all/request`;
  static gatewayLogCountUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/logging/count`;
  static gatewayConnectionDetailsUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/connection/detail`;
  static gatewayLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/logging`;
  static gatewayNetworkTree = `${ApiUrls.baseUrl}nebulae/api/v1/nms/network/diagram/${UrlParameter.nodeId}`;
  static gatewayNetworkDiagnostic = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/network/diagnostic/${UrlParameter.nodeId}`;
  static allgatewayNetworkTree = `${ApiUrls.baseUrl}nebulae/api/v1/nms/network/diagram`;
  // static gatewayUpTime = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/uptime/chart/${UrlParameter.deviceId}/${UrlParameter.starttime}/${UrlParameter.endtime}`;
  static gatewayUpTimeGraphData = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/uptime`;
  static gatewaySystemInfo = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/profile/${UrlParameter.tokenId}/${UrlParameter.nodeId}`;
  static gatewayUpdate = `${ApiUrls.baseUrl}nebulae/api/v1/node`;
  static allPrelaodedDevices = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/devices/list/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static preloadedDeviceUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/device`;
  static updatePreloadedDeviceUrl = `${ApiUrls.baseUrl}nebulae/api/v1/preloaded/device`;
  static preloadedWhiteListedDeviceUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/whitelist/device`;
  static uploadPreloadedDevice = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/devices/list`;
  static downloadSampleCode = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/devices/sample`;
  static downloadSampleBorderRouterFileUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/devices/sample`;
  static uploadBorderRouterUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nodelist`;

  static gatewayDcuPropertiesData = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dcu/property/${UrlParameter.mac}/${UrlParameter.nodeId}`;
  static gatewayDcuPropertiesDataLive = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dcu/property/live`;
  static gatewayPropertiesRefreshgUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/property`;
  static gatewayRPLPropertiesRefreshgUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/rpl/property`;
  static gatewayDownloadLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/log/request`;
  static gatewayNetworkDiagnosticUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/network/diagnostic`;
  static deviceRebootUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/reboot`;
  static devicetimesync = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/unix/time`;
  static deviceFactoryRestUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/factory/reset`;
  static devicePingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/ping`;
  static deviceDumpAllUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/dump/all/request`;
  static deviceLogCountUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/logging/count`;
  static deviceNonCommissioned = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/device/noncommissioned`;
  // static deviceConnectionDetailsUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/ncp/connection/detail`;
  static deviceLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/logging`;
  static deviceNetworkDiagnostic = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/network/diagnostic/${UrlParameter.mac}`;
  static deviceNetworkTree = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/network/diagram/${UrlParameter.mac}`;
  static deviceNetworkDiagnosticUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/network/diagnostic`;
  static deviceDeleteUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/device`;
  static devicePropertiesRefreshgUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/property`;
  // static deviceRPLPropertiesRefreshgUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/ncp/rpl/property`;
  static deviceDownloadLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/log/request`;
  static gatewayTamperUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/tamper/1/1/0/1/1?orderByColumnIndex=acknowledge&searchString=null`;
  static gatewayAllTamperUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/tamper/3/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayAllPowerStatusUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/power/status/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayTamperUpdateUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/tamper`;
  static gatewayTamperUpdateAllUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/tamper/ackall`;
  static allEventLogsUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/activity/${UrlParameter.nodeId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allFilterEventLogsUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/activity/${UrlParameter.nodeId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allFilterLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/logging/data/${UrlParameter.endNodeDeviceId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allFilterNodeLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/logging/data/${UrlParameter.endNodeDeviceId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allParentSwitchUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/parent/switch/list/${UrlParameter.endNodeDeviceId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static addUser = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/add`;
  static userDeleteUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/delete`;
  static allUsers = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static getuserUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/get/${UrlParameter.userId}`;
  static getUserRoleListUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/role/detail/list`;
  static updateUser = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/update`;
  static userBlock = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/block`;
  static errroCount = `${ApiUrls.baseUrl}nebulae/api/v1/nms/error/count`;
  static gatewayErrorCodeEvent = `${ApiUrls.baseUrl}nebulae/api/v1/nms/error/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static historyEventLogsUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/activity/history/${UrlParameter.nodeId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;


  static gatewayErrorCodeUpdateUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/error/ack/${UrlParameter.errorEvent}`;
  static gatewayErrorCodeAllUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/error/ackall`;

  static userDeassociateGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/gateway/deassociate`;
  static userAssociateGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/gateway/associate`;
  static userAssociatedGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/get/associate/${UrlParameter.userId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static userDeassociatedGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/get/deassociate/${UrlParameter.userId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allNodes = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/list/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayUpTimeDemo = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/uptime/chart/${UrlParameter.deviceId}/${UrlParameter.starttime}/${UrlParameter.endtime}`;
  static allDeviceStatusUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/status/trap/list/${UrlParameter.endNodeDeviceId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static nodeDetailsHistoryURL = `${ApiUrls.baseUrl}nebulae/api/v1/nms/diagnostic/history/data`;
  static allFilterEventsUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/gateway/tamper/3/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayPowerStatusUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dcu/power/status/${UrlParameter.nodeId}/${UrlParameter.mac}`;
  static gatewayPowerStatusRefreshUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/power/status/${UrlParameter.mac}/${UrlParameter.nodeId}`;
  static gatewayPowerStatusInfoUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/gateway/powerStatus/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayDropdownURL = `${ApiUrls.baseUrl}nebulae/api/v1/nms/getdcunamelist/${UrlParameter.tokenId}`;
  static allNodesVersionWiseUrl = `${ApiUrls.baseUrl}nebulae/api/nms/endnode/list`;


  static gatewayHistorydata = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/history/data/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}`;
  static gatewayHistoryChangedData = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/history/change/data/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}`;
  static deviceHistorydata = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/history/data/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}`;
  static deviceHistoryChangedDataUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/history/change/data/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}`;

  /* FoTa API URL */
  //static fotaLoginUrl = `${ApiUrls.fotaBaseUrl}nebulink/fota/oauth/login`;
  static fotaLoginUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/fota/userdetails/${UrlParameter.tokenId}`;
  static fotaDeviceListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/list/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaDeviceStatusUrl = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/status`;
  static fotaDeviceArtifactListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/artifacts/list/${UrlParameter.mac}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaDeviceGroupListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/groups/list/${UrlParameter.mac}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaDeviceDeleteUrl = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/${UrlParameter.nodeId}`;
  static fotDeviceInfo = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/${UrlParameter.nodeId}`;
  static fotaGroupListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/list/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static addFotaGroup = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group`;
  static fotGroupInfo = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/${UrlParameter.groupId}`;
  static fotaNodeListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/node/list/${UrlParameter.mac}/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaChildDeviceStatusUrl = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/child/status`;

  static FOTACredential = `${ApiUrls.baseUrl}nebulae/api/v1/nms/fota/userdetails`;
  static getFOTACredential = `${ApiUrls.FOTACredential}/${UrlParameter.tokenId}`;
  static fotaSubscriptionURL = `${ApiUrls.baseUrl}nebulae/api/v1/nms/fota/subscribe/${UrlParameter.tokenId}?isFotaSubscribe=`;

  static fotaDeviceAssociateListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/except/nodes/list/${UrlParameter.groupId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static associateFotaGroupdeviceURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/associate/nodes`;
  static fotaDeviceDeassociateListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/nodes/list/${UrlParameter.groupId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static deassociateFotaGroupdeviceURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/deassociate/nodes`;
  static associatedDeviceListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/nodes/list/${UrlParameter.groupId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;


  static fotaArtifactListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact/list/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static addFotaArtifact = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact`;
  static fotArtifactInfo = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact/${UrlParameter.artifactId}`;
  static fotaArtifactDownloadReleaseFile = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact/download/${UrlParameter.artifactId}`;
  static fotaArtifactDelete = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact/${UrlParameter.artifactId}`;

  static getReleaseVersionUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/fota/releaseversion`;

  static fotaDeploymentListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/list/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaDeploymentDeviceListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/devices/${UrlParameter.deploymentId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotDeploymentStatus = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/status/${UrlParameter.deploymentId}`;
  static updateDeploymentStatus = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/state`;
  static macValidationForDeployments = `${ApiUrls.baseUrl}nebulae/api/v1/nms/fota/preprocess/${UrlParameter.tokenId}/${UrlParameter.groupId}/${UrlParameter.artifactId}`
  static addFotaDeployment = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment`;
  static deleteDeployment = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/${UrlParameter.deploymentId}`;
  static exportDeploymentDevices = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/export/device/${UrlParameter.deploymentId}/${UrlParameter.btnId}/${UrlParameter.lblValue}`;
  static exportDeploymentLogs = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/logs/${UrlParameter.deploymentId}/${UrlParameter.nodeId}`;
  static pendingDeployemntDevices = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/pending/progress/devices/${UrlParameter.deploymentId}`;
  static stopDeploymentNodeUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/fota`;
  static stopDeploymentBorderRouterUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/fota`;
  static generateArtifactUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/fota/image/utility`;
  static deleteNMSDeploymentUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/fota/set/status/delete/${UrlParameter.deploymentId}`;
  static startFotaDeploymentCheckUrl =`${ApiUrls.baseUrl}nebulae/api/v1/nms/fota/check/deploymentstatus/${UrlParameter.tokenId}/${UrlParameter.deploymentId}`;

  /* FoTa PAGES URL */
  static viewDeviceArtifactPage = `/fota/devices/${UrlParameter.mac}/artifact`;
  static viewDeviceGroupPage = `/fota/devices/${UrlParameter.mac}/group`;
  static viewChildDevicePage = `/fota/devices/${UrlParameter.mac}/node`;
  static viewDeviceAssociatePage = `/fota/groups/${UrlParameter.groupId}/${UrlParameter.groupName}/associate`;
  static viewDeviceDeassociatePage = `/fota/groups/${UrlParameter.groupId}/${UrlParameter.groupName}/deassociate`;
  static viewDeviceAssociatedPagePage = `/fota/groups/associated-devices/${UrlParameter.groupId}/${UrlParameter.groupName}`;
  static viewDeployemntProgressPage = `/fota/deployments/progessstatus/${UrlParameter.deploymentId}`;
  static viewGatewayDetailsTimelinePage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/timeline-graph`;


  static viewGatewayListPage = `/gateways/${UrlParameter.status}`;
  static updategatewayListPage = `/users/edit-user/${UrlParameter.status}`;
  static updateUserPage = `/users/edit-user/${UrlParameter.userId}`;
  static userGatewaysPage = `/users/user-border-routers/${UrlParameter.userId}`;
  static viewGatewayDetailsPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/info`;
  static viewGatewayDetailsUptimePage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/uptime`;
  static viewHistoryPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/history`;
  static viewGatewayDetailsConfigurationPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/configuration`;
  static viewGatewayDetailsDeviceLogPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/logs`;
  static viewGatewayDetailsEventPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/events`;
  static viewGatewayDetailsCommunicationPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/communication`;
  static viewGatewayDetailsDevicesPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/device`;
  static viewGatewayDetailsNetworkPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/network-graph`;
  static allDevices = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/devices/list/${UrlParameter.nodeId}/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static deviceInfo = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/device/info/${UrlParameter.mac}`;
  static viewDeviceDetailsPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/device/${UrlParameter.endNodeDeviceId}`;
  static viewNodeDetailsPage = `/nodes/list/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/device/${UrlParameter.endNodeDeviceId}`;
  static viewRepeatedNodeDetailsPage = `/repeated-nodes/list/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/device/${UrlParameter.endNodeDeviceId}`;
  static deviceDetailInfo = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/${UrlParameter.mac}`;
  static deviceTimeSeriesGraph = `${ApiUrls.baseUrl}nebulae/api/v1/nms/diagnostic/series/data`;
  static getAlldeviceIds = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/device/array`;
  static viewEndNodeDeviceDetailsPage = `/nodes/detail/${UrlParameter.endNodeDeviceId}`;

  /* repeated nodes PAGES URL */
  static AddRepeaterSingle = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/repeater`;
  static AddRepeaterBulk = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/repeater/list`;
  static PreloadedRepeaterList = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/repeater/list/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static RepeaterStatuswiseList = `${ApiUrls.baseUrl}nebulae/api/v1/nms/repeater/list/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static ConnectedDisconnectedendnodeList = `${ApiUrls.baseUrl}nebulae/api/v1/nms/repeater/${UrlParameter.mac}/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;

  /* Live Data URL */
  static viewGatewayDetailsLiveDataPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/livedata`;

  static downloadKeysNode = `${ApiUrls.baseUrl}nebulae/api/v1/node/certs/${UrlParameter.tokenId}/${UrlParameter.mac}`;
  static downloadKeys = `${ApiUrls.baseUrl}nebulae/api/v1/node/certs/${UrlParameter.tokenId}/${UrlParameter.deviceId}`;
  static downloadMultipleNodeCertiUrl = `${ApiUrls.baseUrl}nebulae/api/v1/node/qc/bulk/certs`;
  static downloadMultipleNodeCheckCertiUrl = `${ApiUrls.baseUrl}nebulae/api/v1/node/qc/bulk/certs/check`;

  static terminalUrl = `${ApiUrls.terminalBaseUrl}nebulaeconsole/${UrlParameter.deviceId}`;
  static terminalSession = `${ApiUrls.baseUrl}nebulae/api/v1/remote/terminal/${UrlParameter.deviceId}`;

  static getPowerOutage = `${ApiUrls.baseUrl}nebulae/api/v1/nms/power/outage/${UrlParameter.starttime}/${UrlParameter.endtime}`;

  static authLogs = `${ApiUrls.baseUrl}nebulae/api/v1/nms/authdetails/${UrlParameter.type}/${UrlParameter.starttime}/${UrlParameter.endtime}/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}`;
  static authLogsCommunicationLogs = `${ApiUrls.baseUrl}nebulae/api/v1/nms/authdetails/${UrlParameter.type}/${UrlParameter.mac}/${UrlParameter.starttime}/${UrlParameter.endtime}/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}`;
  static authFaildDetails = `${ApiUrls.baseUrl}nebulae/api/v1/nms/authfailed/devices/${UrlParameter.type}/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}?searchString=${UrlParameter.searchParameter}`;
  static getDeviceList = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/devicelist`
  /* Multicast Notification*/
  static gatewaymulticastnotificationPingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/ping/list`;
  static gatewaymulticastnotificationRebootUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/reboot/list`;
  static gatewayFactoryRestUrlmulticastnotificationUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/factory/reset/list`;
  static gatewayDcuPropertiesDataLivemulticastnotificationUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dcu/property/live/list`;
  static devicemulticastnotificationPingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/ping/list`;
  static devicemulticastnotificationRebootUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/reboot/list`;
  static deviceFactoryRestUrlmulticastnotificationUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/factory/reset/list`;
  static deviceDcuPropertiesDataLivemulticastnotificationUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/property/list`;
  static viewHistory =`${ApiUrls.baseUrl}nebulae/api/v1/nms/bulk/history/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}/${UrlParameter.draw}/${UrlParameter.type}/${UrlParameter.starttime}/${UrlParameter.endtime}`;
  static viewHistoryDetails = `${ApiUrls.baseUrl}nebulae/api/v1/nms/bulk/history/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}/${UrlParameter.draw}/${UrlParameter.type}/${UrlParameter.starttime}`

  /* gateway Configuration*/
  static serverConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/serverconfig/${UrlParameter.mac}`;
  static batteryConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/batteryconfig/${UrlParameter.mac}`;
  static wisunConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/wisunconfig/${UrlParameter.mac}/${UrlParameter.interfaceValue}`;
  static logConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/logconfig/${UrlParameter.mac}`;
  static addServerConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/server/config`;
  static addBatteryConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/battery/config`;
  static addLogConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/log/config`;
  static addWisunConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/wisun/config`;
  static addWisunV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/gateway/wisun/config`;
  static wisunV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/gateway/wisunconfig/${UrlParameter.mac}/${UrlParameter.interfaceValue}`;
  /* gatewaybulk  Configuration*/
  static addServerMultiConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/server/configBulk`;
  static addBatteryMultiConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/battery/configBulk`;
  static addLogMultiConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/log/configBulk`;
  static addWisunMultiConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/wisun/configBulk`;
  static addWisunMultiV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/gateway/wisun/configBulk  `;

  /* node Configuration*/
  static deviceserverConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/serverconfig/${UrlParameter.mac}`;
  static devicewisunConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/wisunconfig/${UrlParameter.mac}`;
  static devicV2eWisunConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/node/wisunconfig/${UrlParameter.mac}`;
  static nodeConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/config/${UrlParameter.mac}`;
  static nodeV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/node/config/${UrlParameter.mac}`;
  static meterConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/meter/config/${UrlParameter.mac}`;
  static logNodeConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/logconfig/${UrlParameter.mac}`;
  static otaConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/otaconfig/${UrlParameter.mac}`;
  static adddeviceServerConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/server/config`;
  static addnodeConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/config`;
  static adddeviceWisunConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/wisun/config`;
  static addMeterConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/meter/config`;
  static addNodeLogConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/log/config`;
  static addOtaConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ota/config`;
  static addNodeV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/node/config`;
  static adddeviceWisunV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/node/wisun/config`;


  /* node Bulk Configuration*/
  static addMultideviceServerConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/server/configBulk`;
  static addMultinodeConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/configBulk`;
  static addMultideviceWisunConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/wisun/configBulk`;
  static addMultiMeterConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/meter/configBulk`;
  static addMultiLogConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/log/logConfigBulk`;
  static addMultiOtaConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ota/configBulk`;
  static addMultideviceWisunV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/node/wisun/configBulk`;
  static addMultinodeV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/node/configBulk`;



  /* device through data get Border Router Configuration*/
  static getDeviceServerConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/server/config/${UrlParameter.mac}`;
  static getDeviceBatteryConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/battery/config/${UrlParameter.mac}`;
  static getDeviceLogConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/log/config/${UrlParameter.mac}`;
  static getDeviceWisunConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/wisun/config/${UrlParameter.mac}/${UrlParameter.interfaceValue}`;
  static getDeviceWisunV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/get/gateway/wisun/config/${UrlParameter.mac}/${UrlParameter.interfaceValue}`;


  /* device through data get Node Configuration*/
  static getDeviceNodeServerConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/server/config/${UrlParameter.mac}`;
  static getDevicenodeConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/config/${UrlParameter.mac}`;
  static getDevicedeviceWisunConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/wisun/config/${UrlParameter.mac}`;
  static getDevicedeviceWisunV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/get/node/wisun/config/${UrlParameter.mac}`;
  static getDevicedeviceMeterConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/meter/config/${UrlParameter.mac}`;
  static getDeviceLogNodeConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/log/config/${UrlParameter.mac}`;
  static getDeviceOtaNodeConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/ota/config/${UrlParameter.mac}`;
  static getDeviceNodeV2ConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/get/node/config/${UrlParameter.mac}`;


  static getWisunInterfaceListUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/interface/info/${UrlParameter.nodeId}`;
  static getGatewayVersion = `${ApiUrls.baseUrl}nebulae/api/v2/nms/gateway/getversion`;
  static getNodeVersion = `${ApiUrls.baseUrl}nebulae/api/v2/nms/node/getversion`;


}

